.menu {
    display: flex;
  justify-content: center; /* Center the menu items horizontally */
    align-items: center;
    font-size: 12px; /* Adjust the font size as needed */
    /*background-color: #000; /* Example background color */
    /*padding: 20px;*/
    margin-top:20px;
    position: absolute;
    bottom: 20px;
    
  }
  
  .menu-item {
    margin: 0px 5px;
   
  }
  
  .menu-link, .menu-text {
    /*color: rgb(193, 190, 190);*/
    text-decoration: none;
    font-size: smaller;
    font-weight: 400;
    color: #fff;

  }
  
  .menu-link:hover {
    text-decoration: none;
    color: rgb(215, 211, 211);
    font-weight: 400;
  }

  .menu-separator {
    margin: 0 5px;
   
  }

  .share-buttons {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 10px 0;
    align-content: flex-end;
    flex-wrap: wrap;
    flex-direction: row;
    position: absolute;
    bottom: 35px;
  }
  
  .share-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-right: 10px;
    
  }
  
  .share-button .facebook-icon,
  .share-button .email-icon,
  .share-button .twitter-icon,
  .share-button .whatsapp-icon,
  .share-button .telegram-icon {
    color: #fff; /* Change this to the desired icon color */
    font-size: 24px; /* Adjust the size as needed */
  }

  
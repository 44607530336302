/* # Mobile
only screen and (min-width: 480px)

# Tablet
only screen and (min-width: 768px) 

# Desktop
only screen and (min-width: 992px)

# Huge
only screen and (min-width: 1280px) 
Or:

# Phone
only screen and (max-width:320px)

# Tablet
only screen and (min-width:321px) and (max-width:768px)

# Desktop 
only screen and (min-width:769px)*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f4f5 !important;
  /*background-color: #1D9BF0 !important */
}

/* body {
  min-height: 1000px !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.completed {
  text-decoration: line-through;
}

.container {
  min-height: 100%;
}

@media only screen and (orientation: portrait) {
  .container-fluid {
    overflow-y: auto; /* Enable vertical scrolling in portrait mode */
    height: 100vh;
  }
}

@media only screen and (orientation: landscape) {
  .container-fluid {
    overflow-y: hidden; /* Disable vertical scrolling in landscape mode */
     height: 150vh;
  }
}
@media (min-width: 990px) {
  .navbar-toggle {
    display: block;
  }
  .navsub {
    padding: 0 11rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    /* text-align: left; */
    width: 100%;
  }

  .navbar-collapse {
    float: right;
  }
}
@media (min-width: 0px) {
  .task-title {
    font-weight: 500;
    font-size: 17px;
  }

  .h1-home-header-font {
    font-family: "Graphik Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px !important;
  }

  .task-speed {
    width: 70px;
    margin-top: -7px;
    margin-left: -17px;
  }
  .task-image {
    width: 50px;
    height: 50px;
  }
  .mobile-task-countdown {
    float: left;
  }
  .mobile-menu {
    display: block;
    display: list-item;
    float: left;
    margin-right: 7px;
    margin-top: 6px;
  }

  .mobile-menu-noti {
    display: list-item;
    float: left;
    margin-right: 7px;
    margin-top: 6px;
  }

  .navbar-brand-img {
    margin-left: -7px;
  }
  .navbar-brand-img-inner {
    margin-top: -5px;
    width: 30px;
  }

  .h1-home-header-dash {
    display: none;
  }
  .footer-text {
    font-size: 15px;
    color: #b1b7ba;
  }
  .footer {
    display: none !important;
  }
  .fab-container {
    /* margin-bottom: 70px !important; */
    margin-right: -15px !important;
    bottom: 0vh !important;
  }

  .plus-button {
    font-size: 18px;
    margin-bottom: 0px;
  }
  .plus-button-plus {
    font-size: 28px;
    margin-bottom: 0px;
  }

  .notification {
    /*background-color: #555; */

    color: white;
    text-decoration: none;
    /* padding: 15px 26px; */
    position: relative;
    display: inline-block;
    border-radius: 2px;
  }

  .icon-noti {
    float: left;
    width: 18px;
  }

  /* .notification:hover {
    /* background: red; */
  /*} */

  .notification .badge {
    float: left;
    display: block;
    /* position: absolute; */
    /* top: 7px; */
    /* right: -40px; */
    /* padding: 5px 10px; */
    border-radius: 50%;
    background: red;
    color: white;
    font-size: 10px;
    font-weight: normal;
    margin-left: -8px;
    /* padding-left: 4px; */
    padding-right: 5px;
    /* float: right; */
    margin-top: -2px;
  }

  .task-font-size {
    font-size: 20px;
  }
  .task-edit-icon {
    width: 15px;
    /* position: absolute; */
    /* margin-top: 77px;
    margin-left: 64px; */
  }

  .task-edit-icon-blue {
    width: 15px;
    position: absolute;
    margin-top: 77px;
    margin-left: 89px;
  }

  .task-dup-icon {
    /* position: absolute;
    margin-top: 78px;
    margin-left: 46px; */
    width: 12px;
  }
  .task-completed-icon {
    position: absolute;
    margin-top: 7px;
    margin-left: -20px;

    width: 16px;
  }

  .h4,
  h4 {
    font-size: 20px !important;
  }
  .task-countdown {
    font-size: 15px !important;
  }

  .task-overdue {
    font-size: 13px;
    font-weight: 600;
    color: red;
  }

  .task-title-div {
    max-width: 210px;
    margin-top: -8px;
    margin-left: 7px;
    font-size: 13px;
  }
  .goal-title-div {
    max-width: 210px;
    margin-top: -9px;
    min-width: 110px;
  }

  .goal-title-div-main {
    /* max-width: 210px; */
    margin-top: 8px;
    /* min-width: 110px; */
  }

  .task-empty-countdown {
    margin-right: 76px;
  }

  .goal-title {
    text-shadow: 1px 1px #000000;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
  }
  .goal-title a {
    font-weight: 500;

    color: #ffffff;
  }
  .goal-title:visited {
    font-weight: 500;
  }
  .goal-title a:link {
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
  }
  .goal-title a:active {
    text-decoration: none;
    color: #ffffff;
  }

  .goal-title a:hover {
    text-decoration: none;
    color: #ffffff;
  }
  .mobile-top-menu {
    /* background: #000; */
    background: rgba(0, 0, 0, 0.6);
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 12px;
    padding-bottom: 10px;
    padding-right: 20px;
  }
}
@media (min-width: 992px) {
  .goal-title {
    text-shadow: 1px 1px #000000;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
  }

  .h1-home-header-font {
    font-family: "Graphik Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 25px !important;
  }

  .task-title {
    font-weight: 500;
    font-size: 20px;
  }

  .task-speed {
    width: 85px;
    margin-top: -7px;
    margin-left: 6px;
  }

  .task-image {
    width: 90px;
    height: 90px;
  }

  .mobile-menu {
    display: none;
  }

  .mobile-menu-noti {
    display: block;
  }

  .mobile-top-menu {
    background: transparent;
  }
  .goal-title a {
    font-weight: 500;
    font-size: 29px;
    color: #ffffff;
  }
  .goal-title:visited {
    font-weight: 500;
    font-size: 29px;
    color: #ffffff;
  }
  .goal-title a:link {
    font-weight: 500;
    font-size: 22px;
    color: #ffffff;
  }
  .goal-title a:active {
    text-decoration: none;
    color: #ffffff;
  }

  .goal-title a:hover {
    text-decoration: none;
    color: #ffffff;
  }

  .task-empty-countdown {
    margin-right: 100px;
  }
  .task-title-div {
    max-width: 100%;
    margin-top: -8px;
    margin-left: 12px;
  }

  .task-countdown {
    font-size: 16px !important;
    margin-top: 0px;
    margin-right: -6px;
  }
  .task-overdue {
    font-size: 17px;
    color: red;
  }
  .h4,
  h4 {
    font-size: 1.5rem;
  }

  .mobile-task-countdown {
    float: right;
  }
  .task-edit-icon {
    position: absolute;
    margin-top: 77px;
    margin-left: 64px;
    width: 15px;
    /*/
    margin-top: 68px;
    margin-left: 76px;
    width: 24px; */
  }
  .task-dup-icon {
    position: absolute;
    margin-top: 69px;
    /*margin-top: 69px;
    margin-left: 48px;
    width: 18px; */
  }

  .task-completed-icon {
    position: absolute;
    margin-top: 76px;
    margin-left: 44px;
    /* margin-top: 70px; */
    /*margin-top: 70px;
    margin-left: -84px;
    width: 22px;*/
  }

  .task-font-size {
    font-size: 35px;
  }

  .notification {
    /*background-color: #555; */
    color: white;
    text-decoration: none;
    /* padding: 15px 26px; */
    position: relative;
    display: inline-block;
    border-radius: 2px;
  }

  .icon-noti {
    float: none;
    width: 18px;
  }

  /* .notification:hover {
    /* background: red; */
  /*} */

  .notification .badge {
    position: absolute;
    top: 10px;
    right: 5px;
    /* padding: 5px 10px; */
    border-radius: 50%;
    background: red;
    color: white;
    font-size: 10px;
    font-weight: normal;
    /* margin-left: -1px; */
    padding-left: 4px;
    padding-right: 5px;
  }

  .navbar-brand-img {
    margin-left: 0px;
  }

  .navbar-brand-img-inner {
    margin-top: 0px;
    width: 30px;
  }
  .plus-button {
    font-size: 18px;
    margin-bottom: 3px;
  }
  .plus-button-plus {
    font-size: 28px;
    margin-bottom: 6px;
  }
  .h1-home-header-dash {
    display: block;
  }
  .footer {
    display: block !important;
  }

  .fab-container {
    bottom: 17vh !important;
    position: fixed !important;
    /* margin: 1em !important; */
    right: 5vw !important;
  }
}

/* Nav CSS */

.nav-link {
  font-size: 16px;
  padding-left: 22px;
  color: #faf4f4 !important;
}
.navsub {
  width: 100%;
}

.navbar-toggler {
  float: right;
}
.navbar-brand {
  margin-right: 0px !important;
}

/* Froms Css */
.red-text {
  color: red;
}
.error-email{
  border-color: red;
  border-width: 1px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #343a40;
  color: #ffffff;
  /* text-align: center; */
  display: flex;
  font-size: 12px;
  /* height: 120px; */
}

p {
  font-size: 25px;
}

.btn-new {
  border-radius: 15px !important;
}

.btn span.glyphicon {
  opacity: 0;
}
.btn.active span.glyphicon {
  opacity: 1;
}

.sign-in-btn-top-menu {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  margin-top: 5px;
}
.sign-up-btn-top-menu {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  margin-top: 5px;
  margin-right: 4px;
}

@media (min-width: 76.25em) {
  .sign-up-btn-top-menu {
    margin-left: 10px;
  }
}

.fab-item {
  position: sticky !important;
  background-color: #007bff !important;
}

.btn-add-task {
  padding-top: 2px !important;
  margin-top: 5px !important;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  /* padding: 0.75rem 1rem; */
  padding: 0px !important;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f2f4f5 !important;
  border-radius: 0.25rem;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.item-title {
  font-weight: 600;
}

.h1,
h1 {
  font-size: 1.5rem !important;
}

.goal-cell {
  margin: 5px;
  min-height: 30px;
  /* line-height: 75px; */
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  padding: 5px;
  color: #ffffff !important;
}

.goal-cell-inner {
  margin: 5px;
  /* height: 80px; */
  /* line-height: 75px; */
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  color: #05a8de !important;
  text-align: center;
  vertical-align: middle;
  padding: 6px;
}

.goal-cell a:hover,
.goal-cell-inner a:hover {
  text-decoration: none;
  color: #ffffff !important;
}

.goal-cell a:link,
.goal-cell-inner a:link {
  text-decoration: none;
  color: #ffffff !important;
}

.goal-cell a:visited,
.goal-cell-inner a:visited {
  text-decoration: none;
  color: #ffffff !important;
}

.goal-cell a:active,
.goal-cell-inner a:active {
  text-decoration: none;
  color: #ffffff !important;
}

.goal-cell-animated {
  border: 1px solid #343a40;
  /* padding: 10px; */
  box-shadow: 5px 10px #888888;
  cursor: pointer;
}

.plus-button-bg {
  background-color: #3f84f8 !important;
  float: right;
  margin-top: 131px;
  bottom: -10vh !important;
}

.icon-delete {
  cursor: pointer;
  width: 30px;
}

.icon-delete-image {
  padding-top: 22px;
  opacity: 0.3;
}
.avatar {
  width: 160px;
  height: 160px;
  /* border-radius: 75px; */
  cursor: pointer;
}

.avatar-circle {
  vertical-align: middle;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-top: -60px;
  border: 4px solid #ffffff;
}

.avatar-circle-small {
  vertical-align: middle;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  /* margin-top: -60px; */
  border: 4px solid #ffffff;
}
.task-square {
  border: 4px solid #ffffff;
  margin-top: -50px;
}

.file-upload-user {
  z-index: -2 !important;
  height: 126px !important;
  /* width: 131px !important; */
  cursor: pointer !important;
}

.image-upload > input {
  display: none;
}

.task-count-due-color {
  color: #30c9e9;
}

.custom-file-label {
  z-index: 0 !important;
}

.footer-link:hover {
  text-decoration: none;
  color: #ffffff !important;
}

.footer-link:link {
  text-decoration: none;
  color: #ffffff !important;
}

.footer-link:visited {
  text-decoration: none;
  color: #ffffff !important;
}

.footer-link:active {
  text-decoration: none;
  color: #ffffff !important;
}

.task-title a:active {
  text-decoration: none;
}


.task-title a:hover {
  text-decoration: none;
}

.task-search-box {
  padding-left: 3px;
  width:92%;
}

.rows-goals {
  margin-right: -29px !important;
  margin-left: -29px !important;
}

.task-img {
  padding-right: 18px;
  margin-left: -15px;
}

.task-main {
  height: 100%;
  min-height: 285px;
}

.goal-header-panel-main {
  margin-bottom: 20px;
  /* min-height: 165px; */
  color: #ffffff;
}
.goal-header-panel {
  margin-bottom: 20px;
  height: 100%;
  min-height: 120px;
  color: #ffffff;
}
.bg-blue {
  background-color: #4267b2 !important;
}
.bg-green {
  background-color: #34aa44 !important;
}

.bg-blue-custom {
  background-color: #9cbdf3 !important;
}

.h1-home-header-home {
  margin-left: 0px;
  font-size: 35px !important;
  color: #4267b2;
  /* text-shadow: rgb(0, 0, 0) 1px 1px; */
}

.h1-home-header {
  margin-left: 0px;
  font-size: 22px !important;
  color: #4267b2;
  /* text-shadow: rgb(0, 0, 0) 1px 1px; */
}

.h1-home-header-fix {
  margin-left: -15px !important;
}
.task-search-input {
  margin-right: 0px;
}

.task-search-input-fix {
  margin-right: -14px;
}

.login-text {
  font-size: 15px !important;
}

p.careers {
  font-size: 14px;
}

.me-name {
  font-size: 25px;
  font-weight: 600;
}

.me-main {
  margin-top: 12px;
  min-height: 300px;
  width: 100%;
  margin-left: 0px !important;
}

.navbar-collapse {
  margin-top: 5px !important;
}

.bg-white {
  border: 1px solid #e0e0e2;
}

.bg-blue {
  border: 1px solid #e0e0e2;
}

.dash-top-box {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.home-dash-bar {
  width: 75px;
}

.dash-top-box-bar {
  width: 20%;
  padding-left: 15px;
  padding-right: 15px;
}

.dash-hr {
  margin-top: 50px;
  margin-bottom: 50px;
  border-top: 1px solid black;
}

.dash-new-link:link {
  color: #888888;
}
.dash-new-link:visited {
  color: #888888;
}
.dash-new-link:hover {
  color: #888888;
}

.dash-new-link-w:link {
  color: #ffffff;
}
.dash-new-link-w:visited {
  color: #ffffff;
}
.dash-new-link-w:hover {
  color: #ffffff;
}

.dash-new-icon {
  width: 16px;
  float: left;
  margin-right: 6px;
  margin-top: 5px;
}

.simple-page {
  min-height: 350px;
}

.filter-icon {
  width: 20px;
}

.homepage-pic {
  /*background-image: url("https://d3ptyyxy2at9ui.cloudfront.net/banner_afternoon-4f6b16.jpg");*/
  /*background-image: url("/images/bg/1f.jpg");*/
  /*background-image: url("/images/bg/2.jpg");*/
  height: 600px;
  margin-top: -75px;
  background-position: center center;
}

.homepage-bg-color {
  /*background-image: url("https://d3ptyyxy2at9ui.cloudfront.net/banner_afternoon-4f6b16.jpg");*/
  /*background-image: url("/images/bg/1f.jpg");*/
  /*background-image: url("/images/bg/2.jpg");*/
  /*height: 100vh; */
  /*margin-top: -75px;*/
  background-position: center center;
  overflow: hidden; /* Prevent scrolling */
}
.navbar-dark,
.navbar.opaque {
  background: #000;
  background: rgba(0, 0, 0, 0.6);
}

.navbar-dark,
.navbar.opaque-dark {
  background: #343a40;
}

.navsub a:active {
  color: #ffffff !important;
}

.navsub a:hover {
  color: #ffffff !important;
}

.navsub a:link {
  color: #ffffff !important;
}

.navsub a:visited {
  color: #ffffff !important;
}

.hm-main {
  font-size: 35px !important;
  text-align: center;
  padding-top: 20px;
}

.fade-background {
  transition: background-image 1s ease-in-out;
}

.row-w {
  background-color: #ffffff;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
}
.row-g {
  background-color: #f3f3f3;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.row-b {
  background-color: #4267b2 !important;
  margin-top: -77px;
  /* margin-left: -15px; */
  /* margin-right: -15px; */
  width: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: -100;
}

/* .custom-toggler.navbar-toggler {
  border-color: #ffffff !important;
}
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(9,55,244, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
} */

.hm-column {
  text-align: center;
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.hm-column img {
  width: 80px;
}

.how-column-img {
  text-align: center;
}
.how-column-img img {
  width: 230px;
}

.input-explain {
  font-size: 10px;
}

.goal-add-task-link {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  margin-bottom: 14px;
  padding-top: 10px;
}

.complete-btn {
  /* padding-top: 12px; */
  float: right;
  margin-bottom: 0px;
}

.hp-box {
  padding-bottom: 20px;
}
.me-box {
}

.hp-box span {
  font-weight: 400;
  font-size: 18px;
}

.hp-facebox {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
}

.hp-comingsoon-logo {
  width: 140px !important;
  height: 50px !important;
  /*border-radius: 50%; */
}

.hp-trophy {
  width: 19px !important;
}

.hp-viewmore {
  padding-top: 20px;
  text-align: center;
  font-size: 22px;
  padding-left: 10%;
  padding-right: 10%;
}

.hp-viewmore a:link {
  font-weight: 500;
  font-size: 22px;
  color: #4267b2;
}

.hp-viewmore a:visited {
  font-weight: 500;
  font-size: 22px;
  color: #4267b2;
}

.hp-viewmore a:hover {
  font-weight: 500;
  font-size: 22px;
  color: #4267b2;
  text-decoration: none;
}

.hp-viewmore a:active {
  font-weight: 500;
  font-size: 22px;
  color: #4267b2;
}

.me-link a:link {
  font-weight: 500;
  font-size: 18px;
  color: #4267b2;
  cursor: pointer;
}

.me-link a:visited {
  font-weight: 500;
  font-size: 18px;
  color: #4267b2;
  cursor: pointer;
}

.me-link a:hover {
  font-weight: 500;
  font-size: 18px;
  color: #4267b2;
  text-decoration: none;
  cursor: pointer;
}

.me-link a:active {
  font-weight: 500;
  font-size: 18px;
  color: #4267b2;
  cursor: pointer;
}

.hp-goalsbox {
  font-weight: 400;
  font-size: 22px;
}

.hm-column-goals {
  text-align: center;
}

.custom-file {
  margin-bottom: 12px !important;
}
.task-due-icon {
  width: 43px;
  margin-top: -7px;
  margin-right: 6px;
}

.percent-text {
  color: #000 !important;
  fill: #000 !important;
}

.task-row {
  margin-left: 0px !important;
  height: 90px;
}

.goal-title-div {
  margin-top: -10px;
}

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100% !important;
  margin-left: 10px;
  margin-bottom: 7px;
  margin-top: 8px;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.model-complete-task {
  padding-top: 7px;
  font-size: 20px;
}

.fb-btn {
  font-family: Helvetica, sans-serif;
  /* font-weight: 700; */
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  /* font-size: calc(0.27548vw + 12.71074px); */
  text-decoration: none;
  /* text-transform: uppercase; */
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #4c69ba;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
  /* padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px); */
  padding: 5px;
  font-size: 17px !important;
  width: 100%;
}

.google-btn {
  font-family: Helvetica, sans-serif !important;
  /* font-weight: 700; */
  -webkit-font-smoothing: antialiased;
  color: #fff !important;
  cursor: pointer;
  display: inline-block !important;
  /* font-size: calc(0.27548vw + 12.71074px); */
  text-decoration: none;
  /* text-transform: uppercase; */
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #dd4b39 !important;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
  /* padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px); */
  padding: 5px !important;
  font-size: 17px !important;
  width: 100%;
  align-content: center !important;
  margin: 0 auto !important;
  font-weight: normal !important;
}

.row-filter {
  padding: 5px;
}

p {
  font-size: 15px;
}

.orsep {
  padding-right: 10px;
  height: 0;
  margin-top: 12px;
  /* margin-bottom: 30px; */
  position: relative;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #fff;
  /* margin-right: 5px; */
}

.btnFacebook {
  width: 165px;
  height: 35px;
  border-radius: 4px;
  background: #3b5998;
  color: white;
  border: 0px transparent;
  text-align: center;
  margin: 5px;
  display: inline-block;
  width: 100%;
}

.custom-control-label {
  width: 200px;
}

.bg-white {
  background-color: #fff !important;
}

.bg-white-read {
  background-color: #fafcfb !important;
}

.bordered {
  border: solid 1px black;
}

/* Coming Soon Style */
.h1-home-header-comingsoon{
  color: #fff;
  font-size: 50px !important;
  font-weight: bold;
  padding-top: 20px;
} 
.h1-home-header-comingsoon-sub{
  color: #fff;
  font-size: 16px;
}
.h2-home-header-comingsoon-sub{
  color: #fff;
  font-size: 16px !important;
} 
.h1-home-header-comingsoon-sub-div{
  padding-top:0px;
}
.container-comingsoon{
  align-items: center !important;
  align-content: center!important;
  justify-content: center!important;
  
  /*background-color: #1D9BF0 !important */

}


.comingHeaderDiv{
  display: flex;
      flex-direction: column;
      -webkit-box-pack: justify;
      -webkit-box-align: center;
      align-items: center;
      overflow: hidden;
      justify-content: space-between;
      height: 85vh !important;
}


.logo-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px;
}
.comingHeader{
  padding-top:70px;
 
  height: 100%;
  
}
.logo {
  width: 120px;
}
.comingsoon-pages-menu-header{
  font-size: 18px !important;
}
.p-comingsoon{
  color: #fff;
  background-color: #000 !important;
  opacity: 1;
  border-radius:13px;
  padding: 12px;
  font-weight: 400;
}
.p-comingsoonb{
  color: #fff;
  /*background-color: #000 !important;*/
  opacity: 1;
  /*border-radius:13px;*/
  padding: 12px;
  font-weight: 500;
  text-align: center;
}
.inlineForm{
  
  display: inline-flex;

}

.comingsoon-pages-menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  /*padding-left: 1.5rem;*/
  padding-right: 22px !important;
  padding-left: 22px !important;
  position: fixed;
  top: 0;
  width: 100%;
  
  z-index: 1000; /* Ensure it stays above other content */
  padding: 10px; /* Optional: Add some padding for better appearance */
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  padding-top:12px;
  
}
.comingsoon-text{
  font-size: 12px;
  font-weight: 300;
}
.comingsoon-pages-menu-backlink{
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  /*margin-left: -10px;*/
  font-size: 14px;

  &:hover {
    color: #666;
  }

  
}

.inlineForm {
  
  
  align-items: center;
}

.inlineForm .form-group {
  margin-right: 3px;
  padding-top: 5px;
}

.inlineForm .form-group:last-child {
  margin-right: 0; /* Remove margin from the last form-group */
}


